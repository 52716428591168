<template>
  <div>
    <h1>Hello</h1>
    <p><a href="mailto:jamestaddadcox@gmail.com" class="first">email </a> | <a href="https://github.com/jamestaddadcox" target="_blank" class="next">github </a> | <a href="https://www.linkedin.com/in/james-tadd-adcox/" target="_blank" class="next">linkedin</a></p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.first {
    padding: 0px 20px 0px 0px;
}
.next {
    padding: 0px 20px;
}

</style>