<template>
  <div>
    <h1>Code</h1>
    <h2><a href="https://github.com/jamestaddadcox/ConwaysZombies" target="_blank">Conway's Zombies</a></h2>
    <p>An implementation of <a href="https://en.wikipedia.org/wiki/Conways_Game_of_Life" target="_blank">Conway's Game of Life</a> in Java, with zombies. Inspired by the 2023 <a href="https://www.coderetreat.org/" target="_blank">Global Day of Coderetreat</a>, hosted in Pittsburgh by <a href="https://codeandsupply.co/" target="_blank">Code & Supply</a>.</p>
    <h2><a href="https://github.com/jamestaddadcox/BulletHeck" target="_blank">Bullet Heck</a></h2>
    <p>A single-screen bullet-hell style game in Python, in which you try to avoid the—astroids? enemy ships? missiles? coming your way.</p>
    <p>Programmed in Python using Pygame.</p>
    <img src="../assets/bulletHeckScreenshot2.jpg" alt="Screenshot from Bullet Heck">
    <h2><a href="https://github.com/jamestaddadcox/CatGame" target="_blank">Cat Game</a></h2>
    <p>A console game in C# in which you play a cat, doing cat things. Sneak past the terrible human! Steal the food!</p>
    <h2>This Site</h2>
    <p>This site is built in Vue.js and hosted on GitHub Pages</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    img {
        max-height: 300px;
        max-width: 90%;
        padding: 20px;
    }
    p a {
        text-decoration: underline;
    }
    h2 {
        /* font-variant: small-caps; */
        font-size: 11pt;
        font-weight: 600;
        padding: 10px 0px 0px 20px;
    }
</style>